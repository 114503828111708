import React, { useState, useEffect } from 'react'
import { Linking, View } from 'react-native';
import { setStartUpsInFirestore } from '../firestore_sync';
import { leadSources, Phase, StartUp } from '../types';
import DraggableFlatList, {
    RenderItemParams,
} from "react-native-draggable-flatlist";
import { ScrollView } from 'react-native-gesture-handler';
import DashboardRow from '../components/eventTimeline/DashboardRow';
import { useLinkProps, useNavigation } from '@react-navigation/native'
import { Button, Paragraph, Subheading, Text } from 'react-native-paper';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { auth } from '../FirebaseSingleton';
import { Duration } from 'luxon';

import useGlobalContext from '../context/GlobalContext';
import Colors from '../styles/colors';
export type StartupTimelinesProps = {
    phase: Phase
}
const StartupTimelines: React.FC<StartupTimelinesProps> = ({ phase }) => {
    const navigation = useNavigation()
    const { data } = useGlobalContext()
    const [toRender, setToRender] = useState<StartUp[]>([])
    const users = data.users
    const [filterAssignedToMe, setFilterAssignedToMe] = useState(false)
    const [sortByRecentlyUpdated, setSortByRecentlyUpdated] = useState(false)
    const [periodOfInterest, setPeriodOfInterest] = useState<Duration>(Duration.fromDurationLike({ days: 1 }))

    const filterAndSort = () => {
        const filtered = data.startups.filter(s => {
            const phaseFilter = (phase === 'archived' && s.archived)
                ||
                (phase === 'early-pipeline' && s.earlyPipeline)
                ||
                (phase === 'non-commercial' && s.nonCommercial)
                ||
                (phase === 'investment' && s.investment)
                ||
                (phase === 'incubator' && s.incubator)
                ||
                (phase === 'in-progress' && (s.inProgress || (!s.investment && !s.archived && !s.earlyPipeline && !s.nonCommercial && !s.incubator)));

            const assignedToMeFilter = !filterAssignedToMe || (auth.currentUser && s.assignees.includes(auth.currentUser.uid));
            return phaseFilter && assignedToMeFilter;

        });
        if (sortByRecentlyUpdated) {
            filtered.sort((a: StartUp, b: StartUp) => {
                return b.lastModified.diff(a.lastModified).as('minutes')
            })
        } if(phase === 'archived'){
            filtered.sort((a: StartUp, b: StartUp) => {
                return b.dateCreated.diff(a.dateCreated).as('minutes')
            })
        }else {
            filtered.sort((a: StartUp, b: StartUp) => {
                return a.order - b.order
            })
        }

        setToRender(filtered)
    }

    useEffect(() => {
        filterAndSort();
    }, [phase, filterAssignedToMe, sortByRecentlyUpdated, data])


    const renderTimeLine = ({ item, drag }: RenderItemParams<StartUp>) => {
        return (
            <View style={{ flex: 1 }}>
                <DashboardRow startup={item} drag={drag} users={users} highlightDuration={periodOfInterest} phase={phase} />
            </View>
        )
    }

    const onDragFinish = async (data: StartUp[]) => {
        for (let i = 0; i < data.length; i++) {
            data[i].order = i
        }
        setToRender([...data])
        await setStartUpsInFirestore(data)
    }

    const isPhaseSelected = (startupPhase: Phase) => {
        return startupPhase == phase
    }

    const toEarlyPipeline = useLinkProps({ to: { screen: 'EarlyPipelineHome' } })
    const toInProgress = useLinkProps({ to: { screen: 'InProgressHome' } })
    const toInvestment = useLinkProps({ to: { screen: 'InvestmentsHome' } })
    const toArchived = useLinkProps({ to: { screen: 'ArchivedHome' } })
    const toNonCommercial = useLinkProps({ to: { screen: 'NonCommercialHome' } })
    const toIncubator = useLinkProps({ to: { screen: 'IncubatorHome' } })
    return (
        <>
            <View style={{ flexDirection: "row" }}>
                <Button
                    mode={isPhaseSelected('early-pipeline') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toEarlyPipeline.onPress}
                    icon={"magnify-scan"}
                >Early Pipeline</Button>
                <Button
                    mode={isPhaseSelected('in-progress') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toInProgress.onPress}
                    icon={"progress-wrench"}
                >In Progress</Button>
                <Button
                    mode={isPhaseSelected('investment') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toInvestment.onPress}
                    icon={'bank'}
                >Investment</Button>
                <Button
                    mode={isPhaseSelected('incubator') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toIncubator.onPress}
                    icon={'egg-outline'}
                >Incubator</Button>
                <Button
                    mode={isPhaseSelected('non-commercial') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toNonCommercial.onPress}
                    icon={'open-source-initiative'}
                >Non Commercial</Button>
                <Button
                    mode={isPhaseSelected('archived') ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={toArchived.onPress}
                    icon={"close-network-outline"}
                >Archived</Button>

                <Button
                    mode={'outlined'}
                    style={{ width: 200, padding: 5, margin: 5, borderColor: 'red' }}
                    onPress={() => navigation.navigate('CreateStartup')}
                    icon={"folder-plus-outline"}
                    color='red'
                >
                    New Startup</Button>
                <Button
                    color={'black'}
                    mode={'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => auth.signOut()}
                    icon={"logout"}
                >
                    Log out</Button>

            </View>
            <View style={{ flexDirection: "row" }}>
                <Button
                    color={'black'}
                    mode={'outlined'}
                    style={{ width: 200, padding: 5, margin: 5, backgroundColor: Colors.Background }}
                    onPress={() => Linking.openURL('https://gitlab.com/thestartupfactory.tech/nx/-/boards?group_by=epic')}
                    icon={"trello"}
                >
                    TI Board
                </Button>
                <BouncyCheckbox
                    onPress={() => { setSortByRecentlyUpdated(!sortByRecentlyUpdated) }}
                    isChecked={sortByRecentlyUpdated}
                    fillColor="blue"
                    iconStyle={{ borderColor: "blue" }}
                    style={{ paddingTop: 10, paddingLeft: 10 }}
                    textComponent={
                        <Subheading style={{ paddingLeft: 10 }}>Show updated recently first</Subheading>
                    }
                />
                <BouncyCheckbox
                    onPress={() => { setFilterAssignedToMe(!filterAssignedToMe) }}
                    isChecked={filterAssignedToMe}
                    fillColor="blue"
                    iconStyle={{ borderColor: "blue" }}
                    style={{ paddingTop: 10, paddingLeft: 10 }}
                    textComponent={
                        <Subheading style={{ paddingLeft: 10 }}>Only Startups I'm assigned to</Subheading>
                    }
                />
                <Button
                    color={'black'}
                    mode={'outlined'}
                    style={{ width: 200, padding: 5, margin: 5, backgroundColor: Colors.Background }}
                    onPress={() => navigation.navigate('Stats')}
                    icon={"trending-up"}
                >
                    Stats
                </Button>
            </View>
            <View style={{ flexDirection: "row" }}>
                <Paragraph style={{ textAlignVertical: 'center', paddingLeft: 10 }}>Highlight updated since:</Paragraph>
            </View>
            <View style={{ flexDirection: "row" }}>

                <Button
                    mode={periodOfInterest.days === 1 ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => { setPeriodOfInterest(Duration.fromDurationLike({ days: 1 })) }}
                    color={'gray'}
                    icon={'tag'}
                > 1 weekday</Button>
                <Button
                    mode={periodOfInterest.days === 2 ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => { setPeriodOfInterest(Duration.fromDurationLike({ days: 2 })) }}
                    color={'gray'}
                    icon={'tag'}
                >2 weekdays</Button>
                <Button
                    mode={periodOfInterest.days === 3 ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => { setPeriodOfInterest(Duration.fromDurationLike({ days: 3 })) }}
                    color={'gray'}
                    icon={'tag'}
                >3 weekdays</Button>
                <Button
                    mode={periodOfInterest.days === 5 ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => { setPeriodOfInterest(Duration.fromDurationLike({ days: 5 })) }}
                    color={'gray'}
                    icon={'tag'}
                >1 week</Button>
                <Button
                    mode={periodOfInterest.days === 10 ? 'contained' : 'outlined'}
                    style={{ width: 200, padding: 5, margin: 5 }}
                    onPress={() => { setPeriodOfInterest(Duration.fromDurationLike({ days: 10 })) }}
                    color={'gray'}
                    icon={'tag'}
                >2 weeks</Button>



            </View>
            <ScrollView style={{ height: '100%' }}>
                <div style={{ marginBottom: '10' }}>
                    <DraggableFlatList
                        data={toRender}
                        onDragEnd={({ data }) => onDragFinish(data)}
                        keyExtractor={(item) => item.uid}
                        renderItem={renderTimeLine}
                        nestedScrollEnabled
                        style={{ flex: 1 }}
                    />
                </div>
            </ScrollView>
        </>
    )
}

export default StartupTimelines

